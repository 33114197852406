import "./App.css";
import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MqttSettings from "./components/pages/mqtt-settings/MqttSettings";
import DeviceList from "./components/pages/device-list/DeviceList";
import Devices from "./components/pages/devices/Devices";
import NavigationBar from "./components/pages/NavigationBar";
import NotFoundPage from "./components/pages/NotFoundPage";
import { links } from "./enums/links";
import Logout from "./components/pages/authentication/Logout";
import Login from "./components/pages/authentication/Login";
import WarningPopup from "./components/assets/popups/WarningPopup"
import { mqttConnectDictionary } from "./dictionary/ua/mqttConnectDictionary";
import { subscribe, unsubscribe } from "./events/event";
import { mqttConnectionLostEventType } from "./enums/eventType";
import Scheduling from "./components/pages/scheduling/Scheduling";
import EditSchedule from "./components/pages/scheduling/EditSchedule";
import EditDevice from "./components/pages/devices/EditDevice";
import useSetCurrentUser from "./components/hooks/useSetCurrentUserResult";
import DevicesManagement from "./components/pages/admin/DevicesManagement";
import { authHelper } from "./helpers/authHelper";
import CertificatesManagement from "./components/pages/admin/CertificatesManagement";

function App() {
  const currentUserResult = useSetCurrentUser();
  const [showWarningPopup, setWarningPopupOpen] = useState(false);
  const [warningText, setWarningText] = useState();

  const mqttConnectionLost = () => {
    setWarningText(mqttConnectDictionary.disconnectedFromMqttText);
    setWarningPopupOpen(true);
  };
  
  useEffect(() => {
    subscribe(
      mqttConnectionLostEventType.mqttConnectionLost,
      mqttConnectionLost
    );

    return () => {
      unsubscribe(
        mqttConnectionLostEventType.mqttConnectionLost,
        mqttConnectionLost
      );
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <NavigationBar currentUserResult={currentUserResult} />
        <Routes>
          <Route path="/" element={<DeviceList />} />

          <Route path={links.connectToMqttPath} element={<MqttSettings />} />

          <Route path={links.deviceListPath} element={<Devices />} />

          <Route path={links.devicesPath} element={<DeviceList />} />

          <Route path={links.schedulingPath} element={<Scheduling />} />

          <Route path={links.logOutPath} element={<Logout />} />

          <Route
            path={`${links.scheduleEditPath}/:id`}
            element={<EditSchedule />}
          />

          <Route
            path={`${links.deviceEditPath}/:id`}
            element={<EditDevice />}
          />

          <Route
            path={links.devicesManagementPath}
            element={
              currentUserResult.isAuthenticated &&
              authHelper.isAdmin(currentUserResult) ? (
                <DevicesManagement />
              ) : (
                <Navigate to={links.loginPath} />
              )
            }
          />
          
          <Route
            path={`${links.certificatesManagementPath}/:deviceId`}
            element={<CertificatesManagement />}
          />

          <Route path={links.loginPath} element={<Login />} />
          <Route path={links.logOutPath} element={<Logout />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
