import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";
import { getExpiredDateTime, getLocalDateTime } from "../../../../helpers/dateTimeHelper";
import classes from "../../../css/pages/admin/certificates-management/certificateRow.module.css";
import DownloadCertificateButtonWrapper from "../assets/DownloadCertificateButtonWrapper";
import RevokeCertificateButtonWrapper from "../assets/RevokeCertificateButtonWrapper";
import { certificatesApi } from "../../../../api/certificatesApi";
import DeleteButton from "../../../assets/buttons/DeleteButton";
import DeleteItemsDialog from "../../../assets/dialogs/DeleteItemsDialog";
import { links } from "../../../../enums/links";
import { deleteCertificateDialogDictionary } from "../../../../dictionary/ua/deleteCertificateDialogDictionary";
import UList from "../../../assets/lists/UList";

const CertificateRow = ({ certificate, setCertificatesList }) => {
  const [open, setOpen] = useState(false);
  const handleOpenDeleteDeviceDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteDeviceDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      certificatesApi.delete(certificate.serialNumber, links.getCertificatesManagementByDeviceEditLink(certificate.deviceId)).then((response) => {
        if (response.success === true) {
          setCertificatesList((prevState) => {
            return prevState.filter((cert) => certificate.serialNumber !== cert.serialNumber);
          });
        }
      });
    }
  };
  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]}`}>
          <div className={classes["commonName-column"]}>
            {certificate.commonName}
          </div>
          <div className={classes["serialNumber-column"]}>
            {certificate.serialNumber}
          </div>
          <div className={classes["createdDate-column"]}>
            {getLocalDateTime(certificate.createdDateTimeUtc)}
          </div>
          <div className={classes["expirationDate-column"]}>
            {getExpiredDateTime(certificate.expirationDateTimeUtc)}
          </div>
          <div className={classes["revoked-column"]}>
            {certificate.revoked
              ? certificatesManagementDictionary.yesText
              : certificatesManagementDictionary.noText}
          </div>
        </div>
        <div className={classes["actions-panel"]}>
          <div className={classes["revoke-certificate-button-column"]}>
            <RevokeCertificateButtonWrapper
              certificate={certificate}
              setCertificatesList={setCertificatesList}
            />
          </div>
          <div className={classes["download-certificate-button-column"]}>
            <DownloadCertificateButtonWrapper certificate={certificate} />
          </div>
          <div className={classes["delete-certificate-button-column"]}>
          <div className={classes["delete-device-column"]}>
            <DeleteButton onClick={handleOpenDeleteDeviceDialog} />
            <DeleteItemsDialog
              open={open}
              onClose={handleCloseDeleteDeviceDialog}
              items={[certificate.serialNumber]}
              deleteItemDialogDictionary={deleteCertificateDialogDictionary}
              UList={UList}
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateRow;