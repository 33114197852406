export const links = {
    connectToMqttLink: "mqtt-settings",
    deviceListLink: "device-list",
    devicesLink: "devices",
    getLoginLink: (redirect) => `${process.env?.REACT_APP_API_BASE_URL}/auth/login?redirectTo=/${redirect}`,
    loginLink: `${process.env?.REACT_APP_API_BASE_URL}/auth/login?redirectTo=/devices`,
    logOutLink: `${process.env?.REACT_APP_API_BASE_URL}/auth/logout?redirectTo=/devices`,    
    schedulingLink: "scheduling",
    connectToMqttPath: "/mqtt-settings",
    addDevicePath: "/add-device",
    deviceListPath: "/device-list",
    devicesPath: "/devices",
    loginPath: "/login",
    logOutPath: "/logout",
    schedulingPath: "/scheduling",
    getSheduleEditLink: (scheduleId) => `../schedule/${scheduleId}`,
    scheduleEditPath: "/schedule",
    getDeviceEditLink: (deviceId) => `../device/${deviceId}`,
    deviceEditPath: "/device",
    devicesManagementLink: "devices-management",
    devicesManagementPath: "/devices-management",
    getCertificatesManagementByDeviceEditLink: (deviceId) => `../certificates-management/${deviceId}`,
    certificatesManagementPath: "/certificates-management",
    certificatesManagementLink: "certificates-management"
  };
  