import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const scheduleApi = {
  create: async function (schedule, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules`,
          method: "POST",
          data: schedule,
        }),
      redirect
    );
  },
  getByUsernameOrShared: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules`,
          method: "GET",
        }),
      redirect
    );
  },
  getOnlySharedForUser: async function (search, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules/shared?search=${search}`,
          method: "GET",
        }),
      redirect
    );
  },
  getOnlyOwnedByUser: async function (search, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules/owned?search=${search}`,
          method: "GET",
        }),
      redirect
    );
  },
  getById: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules/${id}`,
          method: "GET",
        }),
      redirect
    );
  },
  update: async function (schedule, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules/${schedule.id}`,
          method: "PUT",
          data: schedule,
        }),
      redirect
    );
  },
  delete: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/schedules/${id}`,
          method: "DELETE",
        }),
      redirect
    );
  },
};
