import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";

function DeleteItemsDialog(props) {
  const { onClose, open, items, deleteItemDialogDictionary, UList } = props;
  const handleClose = (selectedToDelete) => {
    onClose(selectedToDelete);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-item-dialog-title"
      >
        <DialogTitle id="delete-item-dialog-title">
          {deleteItemDialogDictionary.dialogQuestionDeleteItemsText}
        </DialogTitle>
        <DialogContent>
          <UList items={items} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>
            {deleteItemDialogDictionary.dialogYesResponseText}
          </Button>
          <Button onClick={() => handleClose(false)}>
            {deleteItemDialogDictionary.dialogNoResponseText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteItemsDialog;
