import { useEffect, useState } from "react";
import classes from "../../css/pages/admin/certificates-management/certificatesManagement.module.css";
import CertificateRow from "./certificates-management/CertificateRow";
import CertificateHeader from "./certificates-management/CertificateHeader";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import { links } from "../../../enums/links";
import { certificatesApi } from "../../../api/certificatesApi";
import { Link, useParams } from "react-router-dom";
import { certificatesManagementDictionary } from "../../../dictionary/ua/certificatesManagementDictionary";
import { Paper } from "@mui/material";
import RegenerateCrlButtonWrapper from "./assets/RegenerateCrlButtonWrapper";
import GenerateCertificateButtonWrapper from "./assets/GenerateCertificateButtonWrapper";
import ReturnButton from "../../assets/buttons/ReturnButton";

const CertificatesManagement = () => {
  useDocumentTitle(documentTitleDictionary.certificagesManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const { deviceId } = useParams();
  const currentUserResult = useSetCurrentUserResult();
  const [certificatesList, setCertificatesList] = useState([]);
  const fetchCertificatesByDeviceId = async (deviceId) => {
    try {
      const response = await certificatesApi.getByDeviceId(deviceId, links.getCertificatesManagementByDeviceEditLink(deviceId));
      if (response.data?.length > 0) {
        setCertificatesList(response.data);
      }
    } catch (error) {
      console.error("Error fetching certificates list:", error);
    }
  };
  
  useEffect(() => {
    const fetcCertificates = async () => {
      await fetchCertificatesByDeviceId(deviceId);
    };

    fetcCertificates();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div className={"crl-container"}>
          <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
            <RegenerateCrlButtonWrapper deviceId={deviceId} />
            <GenerateCertificateButtonWrapper
              deviceId={deviceId}
              setCertificatesList={setCertificatesList}
              fetchCertificatesByDeviceId={fetchCertificatesByDeviceId}
            />
          </Paper>
          <Link
            className={classes["return-to-device-management-link"]}
            to={links.devicesManagementPath}
          >
            <ReturnButton>
              {certificatesManagementDictionary.returnToDevicesManagementText}
            </ReturnButton>
          </Link>
        </div>
        {certificatesList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["certificate-header"]}>
                <CertificateHeader />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["certificate-row"]}>
                <div className={classes["rows-container"]}>
                  {certificatesList.map((certificate) => (
                    <CertificateRow
                      key={certificate.id}
                      certificate={certificate}
                      deviceId={deviceId}
                      clientId={certificatesList[0]?.clientId}
                      setCertificatesList={setCertificatesList}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>
            {certificatesManagementDictionary.noAvailableCertificatesText}
          </h2>
        )}
      </div>
    )
  );
};

export default CertificatesManagement;
