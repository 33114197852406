import React from "react";
import classes from "../../css/assets/popups/warningPopup.module.css";

import { Dialog, DialogContent } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const WarningPopup = ({ open, text, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}>

      <DialogContent>
        <div className={classes["popup-body"]}>
          <h3>{text}</h3>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export default WarningPopup;
