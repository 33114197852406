export const scheduleDesigner = {
    radioSilenceForNMinutesButtonText: "Радіотиша впродовж n хвилин",
    radioSilenceUntilDatetimeButtonText: "Радіотиша до конкретної дати",    
    radioSilenceUntilTimestampButtonText: "Радіотиша до конкретної години",
    waitForNSecondsButtonText: "Чекати впродовж n секунд",
    waitUntilDatetimeButtonText: "Чекати до конкретної дати",
    waitUntilTimestampButtonText: "Чекати до конкретної години",
    waitUntilIncomingCallFromPhoneNumberButtonText: "Чекати до дзвінка з конкретного номера",
    waitUntilAnyIncomingCallButtonText: "Чекати до будь-якого дзвінка",
    dialFromNGsmToPhoneNumberButtonText: "Подзвонити з n GSM-модуля на номер",
    hangUpActiveCallForNGsmButtonText: "Завершити активний дзвінок для n GSM-модуля",
    setGsmModeButtonText: "Встановити режим GSM",
    setMp3FileNumberForGsmPlaybackButtonText: "Встановити mp3-файл для програвання через GSM",
    setRadioModeButtonText: "Встановити режим Радіо",
    setMp3FileNumberForRadioPlaybackButtonText: "Встановити mp3-файл для програвання через рацію",
    setRadioChannelForFirstTransceiverButtonText: "Встановити радіо-канал для 1го трансивера",
    setRadioChannelForSecondTransceiverButtonText: "Встановити радіо-канал для 2го трансивера",
    setRadioChannelForBothTransceiverButtonText: "Встановити радіо-канал для двох трансиверів",
    playButtonText: "Включити програвання",
    playGSMMonologueFileButtonText: "Програвання GSM monologue",
    playRadioDMRFileButtonText: "Програвання Radio DMR",
    onceButtonText: "Один раз",
    repeatNTimesButtonText: "Повторити n разів",
}