import classes from "../../../css/pages/scheduling/commands/hangUpActiveCallForGsmModule.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { hangUpOption, hangUpOptionLabels } from "../../../../enums/scheduling/hangUpOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hangUpActiveCallForGsmModuleSchema } from "../../../../validation/validationSchemas";
import { gsmModuleNumbers } from "../../../../enums/gsmModuleNumbers";

const HangUpActiveCallForGsmModule = ({ setCommandsArray }) => {
  const [gsmModule, setGsmModule] = useState("");

  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(hangUpActiveCallForGsmModuleSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("gsmModule").invalid;
  }

  const onSubmit = () => {
    setCommandsArray((prev) => {
      return [
        ...prev,
        hangUpOptionLabels[hangUpOption.hangUpActiveCallFroGsmModule](gsmModule)
      ]
    })
  };

  const handleChange = (event) => {
    setGsmModule(event.target.value);
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          className={`${buttonClasses["hang-up-active-call-for-gsm-module-button"]}`}
        >
          {scheduleDesigner.hangUpActiveCallForNGsmButtonText}
        </Button>
        <div className={classes["gsm-module-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <Select
              className={classes["gsm-module-select"]}
              value={gsmModule}
              size="small"              
              {...register("gsmModule")}
              onChange={handleChange}
            >
              {gsmModuleNumbers.map((gm) => 
                <MenuItem key={gm} value={gm}>{gm}</MenuItem>
              )}
            </Select>
            <FormHelperText>{errors?.gsmModule?.message}</FormHelperText>
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default HangUpActiveCallForGsmModule;
