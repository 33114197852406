import Paho from "paho-mqtt";
import { Handle } from "./messageHandler";
import { errorType } from "../enums/errorType";
var mqttClient;

export async function Connect(
  host,
  port,
  username,
  password,
  clientId,
  cleanSession,
  onErrorHandler,
  onConnectedHandler,
  onConnectionLostHandler
) {
  return new Promise((resolve) => {
    try {
      mqttClient = new Paho.Client(host, port, clientId);
      mqttClient.onConnectionLost = (event) => {
        onConnectionLostHandler(event);
      };

      let options = {
        useSSL: true,
        cleanSession: cleanSession,
        timeout: 10,
        keepAliveInterval: 30,
        reconnect: true,
        userName: username || "",
        password: password || "",
        onSuccess: () => {
          onConnectedHandler();
        },
        onFailure: (event) => {
          console.log('failure to reconnect');
          onErrorHandler(errorType.mqttConnectionError);
        },
      };

      mqttClient.onMessageArrived = Handle;
      mqttClient.connect(options);
      resolve();
    } catch (error) {
      onErrorHandler(errorType.mqttConnectionError);
    }
  });
}

export async function Publish(topic, message, qos, cleanSession, retained) {
  return new Promise((resolve) => {
    try {
      let mqttMessage = new Paho.Message(message.toString());
      mqttMessage.destinationName = topic;
      mqttMessage.qos = qos;
      mqttMessage.cleanSession = cleanSession;
      mqttMessage.retained = retained;
      mqttClient.send(mqttMessage);
      resolve();
    } catch (error) {
      throw error;
    }
  });
}

export async function Subscribe(topic, qos) {
  return new Promise((resolve) => {
    try {
      let subscribeOptions = {
        qos: qos,
      };

      mqttClient.subscribe(topic, subscribeOptions);
      resolve();
    } catch (error) {
      throw error;
    }
  });
}

export async function Unsubscribe(topic, qos) {
  return new Promise((resolve) => {
    try {
      mqttClient.unsubscribe(topic);
      resolve();
    } catch (error) {
      throw error;
    }
  });
}