import React from "react";
import WaitForNSeconds from "./commands/WaitForNSeconds";
import WaitUntilDatetime from "./commands/WaitUntilDatetime";
import WaitUntilTimestamp from "./commands/WaitUntilTimestamp";
import WaitUntilIncomingCallFromPhoneNumber from "./commands/WaitUntilIncomingCallFromPhoneNumber";
import WaitUntilAnyIncomingCall from "./commands/WaitUntilAnyIncomingCall";
import DialFromNGsmToPhoneNumber from "./commands/DialFromNGsmToPhoneNumber";
import HangUpActiveCallForGsmModule from "./commands/HangUpActiveCallForGsmModule";
import SetGsmMode from "./commands/SetGsmMode";
import SetRadioMode from "./commands/SetRadioMode";
import Once from "./commands/Once";
import RepeatNTimes from "./commands/RepeatNTimes";
import { OutlinedInput, Paper, Typography } from "@mui/material";
import { schedulingDictionary } from "../../../dictionary/ua/scheduling/schedulingDictionary";
import classes from "../../css/pages/scheduling/scheduleBuilder.module.css";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import SetRadioChannelForBothTransceivers from "./commands/SetRadioChannelForBothTransceivers";

const ScheduleBuilder = ({ scheduleString, setCommandsArray }) => {
  const deleteLatestCommandFromTheEndHandler = () => {
    setCommandsArray((prev) => {
      prev = prev.slice(0, -1);
      return [...prev];
    });
  };

  const deleteAllCommands = () => {
    setCommandsArray((prev) => {
      return [];
    });
  };

  return (
    <div className={classes["schedule-builder-container"]}>
      <div className={classes["schedule-commands-container"]}>
        <Typography variant="h6">Очікування</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <WaitForNSeconds setCommandsArray={setCommandsArray} />
        </Paper>

        <Typography variant="h6">GSM</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <DialFromNGsmToPhoneNumber setCommandsArray={setCommandsArray} />

          <HangUpActiveCallForGsmModule setCommandsArray={setCommandsArray} />

          <SetGsmMode setCommandsArray={setCommandsArray} />
        </Paper>

        <Typography variant="h6">Радіо</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <SetRadioMode setCommandsArray={setCommandsArray} />

          <SetRadioChannelForBothTransceivers
            setCommandsArray={setCommandsArray}
          />
        </Paper>
      </div>

      <div className={classes["schedule-string-container"]}>
        <span>{schedulingDictionary.scheduleLabelText}</span>
        <OutlinedInput
          className={classes["schedule-string-control"]}
          id="schedule"
          type="text"
          name="schedule"
          size="small"
          value={scheduleString}
        />
      </div>

      <div className={classes["schedule-string-buttons-container"]}>
        <Button
          className={`${buttonClasses["delete-latest-command-from-the-end-button"]}`}
          disabled={false}
          onClick={deleteLatestCommandFromTheEndHandler}
        >
          {schedulingDictionary.deleteLatestCommandButtonText}
        </Button>

        <Button
          className={`${buttonClasses["clear-schedule-field-button"]}`}
          disabled={false}
          onClick={deleteAllCommands}
        >
          {schedulingDictionary.clearScheduleFieldButtonText}
        </Button>
      </div>
    </div>
  );
};

export default ScheduleBuilder;
