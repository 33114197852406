import DeviceStatusUpdater from "../../../../deviceStatusUpdaters/DeviceStatusUpdater";
import classes from "../../../../css/pages/devices-list/profiles/gsm-gate/deviceRow.module.css";
import { Tooltip } from "@mui/material";
import GsmGateDIStatus from "../../../../statuses/profiles/gsm-gate/GsmGateDIStatus";
import GsmGateGsmStatus from "../../../../statuses/profiles/gsm-gate/GsmGateGsmStatus";
import { onlineStatus } from "../../../../../enums/onlineStatus";
import { devicesListDictionary } from "../../../../../dictionary/ua/devicesListDictionary";

const DeviceRow = ({ device, setDeviceList }) => {
  const getOnlineStatusClass = () => device.online ? onlineStatus.online : onlineStatus.offline;
  const handleSelectDeviceClick = () => {
    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const dev = prevState[profile].array.find(
          (d) => d.clientId === device.clientId
        );
        return dev ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === device.clientId
                ? {
                    ...d,
                    selectedForSendingMessage: !d.selectedForSendingMessage,
                  }
                : d
            ),
          },
        };
      }
    });
  };

  return (
    <Tooltip
      arrow
      placement="right"
    >
      <div className={`${classes["row-wrapper"]}`}>
        <DeviceStatusUpdater setDeviceList={setDeviceList} />

        <div
          className={`${classes["wrapped-area"]} ${
            classes[getOnlineStatusClass()]
          }`}
        >
          <div className={classes["name"]}>{device.name} {devicesListDictionary.getRevokedDeviceNote(device.revoked)}</div>

          <div className={classes["row"]}>
            <div className={`${classes["column"]} ${classes["gsm-column"]}`}>
              <GsmGateGsmStatus device={device} />
            </div>

            <div className={`${classes["column"]} ${classes["di-column"]}`}>
              <GsmGateDIStatus device={device} />
            </div>

            <div
              className={`${classes["column"]} ${classes["select-device-column"]}`}
            >
              <div className={classes["select-device-checkbox-wrapper"]}>
                <label htmlFor="select-device-checkbox">Вибрати</label>
                <input
                  id="select-device-checkbox"
                  type="checkbox"
                  className={classes["select-device-checkbox"]}
                  onChange={handleSelectDeviceClick}
                  checked={device.selectedForSendingMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default DeviceRow;
