import { mqttConnectDictionary } from "../../dictionary/ua/mqttConnectDictionary";

export const mqttSettingsErrorMapper = {
    FailedToUpdateSchedule: mqttConnectDictionary.mqttSettingsNotUpdatedErrorText,
    FailedToRetrieveMqttSettings: mqttConnectDictionary.mqttSettingsNotRetrievedErrorText
};

export const mqttSettingsErrorCode = {
    FailedToUpdateMqttSettings: "FailedToUpdateMqttSettings",
    FailedToRetrieveMqttSettings: "FailedToRetrieveMqttSettings"
};