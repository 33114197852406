export const certificatesManagementDictionary = {
    commonNameHeaderText: "Ім'я сертифікату",
    serialNumberHeaderText: "Серійний номер",
    createdDateTimeUtcHeaderText: "Дата створення",
    expirationDateTimeUtcHeaderText: "Дійсний до",
    revokedHeaderText: "Відкликаний",
    noAvailableCertificatesText: "Немає доступних сертифікатів",
    expiredDateText: "Протермінований",    
    generateCertificateButtonLabel: "Згенерувати новий сертифікат",
    revokeCertificateButtonLabel: "Відкликати сертифікат",
    undoRevocationButtonLabel: "Відмінити відкликання",
    downloadCertificateButtonLabel: "Завантажити сертифікат",
    regenerateCrlButtonLabel: "Перегенерувати crl файл",
    yesText: "Так",
    noText: "Ні",
    returnToDevicesManagementText: "Повернутись до сторінки управління пристроями"
}