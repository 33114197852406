import { useEffect, useState } from "react";
import classes from "../../css/pages/devices/devices.module.css";
import { deviceApi } from "../../../api/deviceApi";
import AddDevice from "./AddDevice";
import DeviceRow from "./DeviceRow";
import DeviceHeader from "./DeviceHeader";
import { devicesDictionary } from "../../../dictionary/ua/devicesDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import deviceMapper from "../../../mappers/deviceMapper";
import { links } from "../../../enums/links";

const Devices = () => {
  useDocumentTitle(documentTitleDictionary.devicesTitle + " - " + documentTitleDictionary.mainTitle);
  const currentUserResult = useSetCurrentUserResult();
  const [deviceList, setDeviceList] = useState([]);

  const fetchDeviceList = async () => {
    try {
      const response = await deviceApi.get(links.deviceListLink);
      if (response.data?.length > 0) {
        const mappedDevices = response.data.map((device) => deviceMapper.mapToDeletableObject(device));
        setDeviceList(mappedDevices);
      }
    } catch (error) {
      console.error("Error fetching device list:", error);
    }
  };
  
  useEffect(() => {
    const fetchDevices = async () => {
      await fetchDeviceList();
    };

    fetchDevices();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div className={classes["add-device-container"]}>
          <AddDevice
            setDeviceList={setDeviceList}
            currentUsername={currentUserResult.currentUsername}
          />
        </div>

        {deviceList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["device-header"]}>
                <DeviceHeader
                  deviceList={deviceList}
                  setDeviceList={setDeviceList}
                  username={currentUserResult.currentUsername}
                />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["device-row"]}>
                <div className={classes["rows-container"]}>
                  {deviceList.map((device) => (
                    <DeviceRow
                      key={device.id}
                      device={device}
                      setDeviceList={setDeviceList}
                      username={currentUserResult.currentUsername}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>{devicesDictionary.noAvailableDeviceText}</h2>
        )}
      </div>
    )
  );
};

export default Devices;
