import classes from "../../../css/pages/scheduling/commands/waitForNSeconds.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { waitDurationOption, waitDurationOptionLabels } from "../../../../enums/scheduling/waitDurationOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { waitForNSecondsSchema } from "../../../../validation/validationSchemas";

const WaitForNSeconds = ({ setCommandsArray }) => {
  const [seconds, setSeconds] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(waitForNSecondsSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("seconds").invalid;
  }
  
  const onSubmit = () => {
    setCommandsArray((prev) => {
      return [
        ...prev,
        waitDurationOptionLabels[waitDurationOption.forNSeconds](seconds)
      ]
    })
  };

  const handleChange = (event) => {
    setSeconds(event.target.value);
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["wait-for-n-seconds-button"]}`}>
          {scheduleDesigner.waitForNSecondsButtonText}
        </Button>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <OutlinedInput
              type="text"
              size="small"
              value={seconds}
              {...register("seconds")}
            />
             <FormHelperText>{errors?.seconds?.message}</FormHelperText>
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default WaitForNSeconds;
