import classes from "../../../css/pages/scheduling/commands/waitUntilAnyIncomingCall.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { waitDurationOption, waitDurationOptionLabels } from "../../../../enums/scheduling/waitDurationOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";

const WaitUntilAnyIncomingCall = ({ setCommandsArray }) => {
  const {
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = () => {
    setCommandsArray((prev) => {
      return [
        ...prev,
        waitDurationOptionLabels[waitDurationOption.untilAnyIncomingCall]()
      ]
    })
  };
  
  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          className={`${buttonClasses["wait-until-any-incoming-call-button"]}`}
        >
          {scheduleDesigner.waitUntilAnyIncomingCallButtonText}
        </Button>
      </form>
    </div>
  );
};

export default WaitUntilAnyIncomingCall;
