import { useEffect } from "react";
import { Connect } from "../../mqtt/mqttService";
import config from "../../config";
import { mqttSettingsApi } from "../../api/mqttSettingsApi";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";
import { useMqttConnectionContext } from "../context/AppContext";
import { mqttConnectionLostEventType } from "../../enums/eventType";
import { publish } from "../../events/event";
import { errorType } from "../../enums/errorType";

const useConnectToMqtt = ({currentUserResult}) => {
  const { connectionStatus, updateConnectionStatus } = useMqttConnectionContext();

  const onConnectedHandler = (e) => {
    updateConnectionStatus(mqttConnectionStatus.isConnected);
  };

  const onConnectionLostHandler = (e) => {
    publish(mqttConnectionLostEventType.mqttConnectionLost);
    updateConnectionStatus(mqttConnectionStatus.isNotConnected);
  };

  const onErrorHandler = (error) => {
    switch (error) {
      case errorType.mqttConnectionError:
        updateConnectionStatus(mqttConnectionStatus.isNotConnected);
        break;
      default:
    }
  };

  const isConnectedToMqtt = connectionStatus === mqttConnectionStatus.isConnected;

  const connectToMqtt = async () => {
    if (!isConnectedToMqtt && currentUserResult.isAuthenticated) {
      try {
        const response = await mqttSettingsApi.getByOwnerUsername();
        const { host, port, username, password } = response.data;

        await Connect(
          host,
          parseInt(port),
          username,
          password,
          config.mqttConfig.myClientId,
          config.mqttConfig.cleanSession,
          onErrorHandler,
          onConnectedHandler,
          onConnectionLostHandler
        );

        updateConnectionStatus(mqttConnectionStatus.isConnecting);
      } catch (error) {
        onErrorHandler(error);
      }
    }
  };

  useEffect(() => {
    connectToMqtt();
    
    const interval = setInterval(() => {
      connectToMqtt();
    }, config.reconnectToMqttMilliSeconds);

    return () => {
      clearInterval(interval);
    };
  }, [currentUserResult]);
};

export default useConnectToMqtt;
