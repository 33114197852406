import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const mqttSettingsApi = {
  getByOwnerUsername: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/mqttsettings/owned`,
          method: "GET",
        }),
      redirect
    );
  },
  updateOrCreate: async function (mqttSettings, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: "api/mqttsettings",
          method: "PUT",
          data: mqttSettings,
        }),
      redirect
    );
  },
  restoreDefaultSettingsByOwnerUsername: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/mqttsettings/restore`,
          method: "PUT",
        }),
      redirect
    );
  },
  getByGroupname: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/mqttsettings/groupname/DefaultGroup1`,
          method: "PUT",
        }),
      redirect
    );
  },
};
